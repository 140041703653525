import { ThunkDispatch } from "redux-thunk";
import {
  ActionTypes,
  ChangeProduct,
  ChangeStateOfProduct,
  DeleteProduct,
  ErrorProducts,
  FetchedClientProducts,
  FetchedLabelProducts,
  FetchedPayOutProducts,
  FetchedProducts,
  FetchedReturnProducts,
  FetchedSingleProduct,
  LoadingProducts,
} from "./types";
import Firebase from "../../components/Firebase/Firebase";
import { ProductModel, ProductStates } from "../../models/Product";

const fetchDetails = async (firebase: Firebase, result: any) => {
  if (!result) throw new Error("could not get products");

  const products: ProductModel[] = await Promise.all(
    result.docs.map(
      async (doc: { data: () => ProductModel; id: string | undefined }) => {
        let newProduct: ProductModel = doc.data();
        newProduct.id = doc.id;
        // @ts-ignore
        newProduct.validUntil = new Date(doc.data().validUntil?.seconds * 1000);

        if (!newProduct.pictures) {
          console.log("no picture list found", newProduct.id);
          newProduct.urls = [];
        } else {
          try {
            newProduct.urls = await Promise.all(
              newProduct.pictures.map(async (picId) => {
                try {
                  return await firebase.doGetSpecificFile(picId);
                } catch (e) {
                  console.log(e);
                  console.log("at product", newProduct.id);
                  return "";
                }
              })
            );
          } catch (e) {
            console.log(e);
            console.log("at product", newProduct.id);
            newProduct.urls = [];
          }
        }
        return newProduct;
      }
    )
  );
  return products;
};

export const fetchProducts = (): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      FetchedProducts | ErrorProducts | LoadingProducts
    >
  ) => {
    dispatch({
      type: ActionTypes.LOADING_PRODUCTS,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");
      const result = await firebase.doGetAllProducts();
      const products = await fetchDetails(firebase, result);

      dispatch({
        type: ActionTypes.FETCHED_PRODUCTS,
        products,
        initialFetch: true,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_PRODUCTS,
        error,
        loading: false,
      });
    }
  };
};

export const fetchSingleProduct = (id: string): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      FetchedSingleProduct | ErrorProducts | LoadingProducts
    >
  ) => {
    dispatch({
      type: ActionTypes.LOADING_PRODUCTS,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");
      const result = await firebase.doGetSpecificProduct(id);
      if (!result) throw new Error("could not get product: " + id);
      if (!result.exists) throw new Error("product does not exist: " + id);
      let data = result.data();
      if (data === undefined) throw new Error("no product data: " + id);
      let product: ProductModel = data;
      product.id = result.id;
      // @ts-ignore
      product.validUntil = new Date(data.validUntil?.seconds * 1000);

      if (!product.pictures) throw new Error("no picture list found");
      product.urls = await Promise.all(
        product.pictures.map((picId) => firebase.doGetSpecificFile(picId))
      );

      dispatch({
        type: ActionTypes.FETCHED_SINGLE_PRODUCT,
        product,
        initialFetch: true,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_PRODUCTS,
        error,
        loading: false,
      });
    }
  };
};

export const fetchPayOutProducts = (): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      FetchedPayOutProducts | ErrorProducts | LoadingProducts
    >
  ) => {
    dispatch({
      type: ActionTypes.LOADING_PRODUCTS,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");
      const result = await firebase.doGetAllPayOutProducts();
      let products: ProductModel[] = [];
      if (!result.empty) products = await fetchDetails(firebase, result);

      dispatch({
        type: ActionTypes.FETCHED_PAYOUT_PRODUCTS,
        products,
        initialFetchPayout: true,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_PRODUCTS,
        error,
        loading: false,
      });
    }
  };
};

export const fetchLabelProducts = (): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      FetchedLabelProducts | ErrorProducts | LoadingProducts
    >
  ) => {
    dispatch({
      type: ActionTypes.LOADING_PRODUCTS,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");
      const result = await firebase.doGetAllLabelProducts();
      let products: ProductModel[] = [];
      if (!result.empty) products = await fetchDetails(firebase, result);

      dispatch({
        type: ActionTypes.FETCHED_LABEL_PRODUCTS,
        products,
        initialFetchLabel: true,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_PRODUCTS,
        error,
        loading: false,
      });
    }
  };
};

export const fetchReturnProducts = (): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      FetchedReturnProducts | ErrorProducts | LoadingProducts
    >
  ) => {
    dispatch({
      type: ActionTypes.LOADING_PRODUCTS,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");

      const result = await firebase.doGetAllReturnProducts();
      let productsToReturn: ProductModel[] = [];
      if (!result.empty)
        productsToReturn = await fetchDetails(firebase, result);

      dispatch({
        type: ActionTypes.FETCHED_RETURN_PRODUCTS,
        productsToReturn,
        initialFetchReturn: true,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_PRODUCTS,
        error,
        loading: false,
      });
    }
  };
};

export const fetchClientsProducts = (id: string): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      FetchedClientProducts | ErrorProducts | LoadingProducts
    >
  ) => {
    dispatch({
      type: ActionTypes.LOADING_PRODUCTS,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");
      const result = await firebase.doGetAllProductsOfClient(id);
      let products: ProductModel[] = [];
      if (!result.empty) products = await fetchDetails(firebase, result);

      dispatch({
        type: ActionTypes.FETCHED_CLIENT_PRODUCTS,
        products,
        initialFetchClient: id,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_PRODUCTS,
        error,
        loading: false,
      });
    }
  };
};

export const deleteProduct = (id: string): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      DeleteProduct | ErrorProducts | LoadingProducts
    >
  ) => {
    dispatch({
      type: ActionTypes.DELETE_PRODUCT,
      id: id,
      loading: false,
    });
  };
};

export const changeStateOfProduct = (
  id: string,
  newState: ProductStates
): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      ChangeStateOfProduct | ErrorProducts | LoadingProducts
    >
  ) => {
    dispatch({
      type: ActionTypes.CHANGE_STATE_OF_PRODUCT,
      id: id,
      state: newState,
      loading: false,
    });
  };
};

export const changeProduct = (changedProduct: ProductModel): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      ChangeProduct | ErrorProducts | LoadingProducts
    >
  ) => {
    const firebase = Firebase.getInstance();

    if (changedProduct.pictures) {
      changedProduct.urls = await Promise.all(
          changedProduct.pictures.map(async (picId) => {
            try {
              return await firebase.doGetSpecificFile(picId);
            } catch (e) {
              console.log(e);
              console.log("at product", changedProduct.id);
              return "";
            }
          })
      );
    }

    dispatch({
      type: ActionTypes.CHANGE_PRODUCT,
      product: changedProduct,
      loading: false,
    });
  };
};
