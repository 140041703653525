import { ClientModel } from "../models/Client";
import { ProductModel } from "../models/Product";
import { send } from "emailjs-com";

type EmailTemplateProperties = {
  firstname: string;
  lastname: string;
  nr_products: string;
  plural_n: string;
  singular_s: string;
  products_list: string;
  total: string;
  to_email: string;
};

type EmailContentProperties = {
  content: string;
  to_email: string;
};

type Props = {
  client: ClientModel;
  products: ProductModel[];
  total: number;
};

export const getEmailContent = ({ client, products, total }: Props) =>
  `<div style="font-family: Tahoma, inherit,sans-serif; font-size: 12pt"><p>Guten Tag ${
    client.firstname
  } ${client.lastname}</p><p>
Wir freuen uns, Ihnen mitzuteilen, dass von Ihnen ${products.length} Produkt${
    products.length > 1 ? "e" : ""
  } verkauft wurde${products.length > 1 ? "n" : ""}, welche${
    products.length > 1 ? "" : "s"
  } noch nicht an Sie ausbezahlt wurde${products.length > 1 ? "n" : ""}. 
</p><table border="0px" cellpadding="8px">${products
    .map(
      (p) =>
        `<tr><td>${p.title}</td><td>${p.internalPrice?.toFixed(
          2
        )} CHF</td></tr>`
    )
    .join("")}</table><p>Ihr Guthaben von ${total.toFixed(
    2
  )} CHF können Sie sich zu unseren Öffnungszeiten gerne ausbezahlen lassen.</p><p>Freundliche Grüsse
<br />Schuhstall Secondhand
</p><p>Fr 13:30 - 18:30<br/>
Sa 09:00 - 16:00
</p></div>`;

export const sendEmailWithContent = ({ client, products, total }: Props) => {
  const template: EmailContentProperties = {
    content: getEmailContent({ client, products, total }),
    to_email: client.email ?? "",
  };

  return send(
    "service_biq6i2q",
    "template_2xy7nnc",
    template,
    "user_AhJhgm8z5Ip5IdvtedcPo"
  );
};

export const sendEmailWithTemplate = ({ client, products, total }: Props) => {
  const template: EmailTemplateProperties = {
    firstname: client.firstname ?? "",
    lastname: client.lastname ?? "",
    nr_products: `${products.length} Produkt${products.length > 1 ? "e" : ""}`,
    plural_n: products.length > 0 ? "n" : "",
    singular_s: products.length > 0 ? "" : "s",
    products_list: `<table border="0px" cellpadding="8px" style="font-family: Tahoma, inherit,sans-serif; font-size: 12pt">${products
      .map(
        (p) =>
          `<tr><td>${p.title}</td><td>${p.internalPrice?.toFixed(
            2
          )} CHF</td></tr>`
      )
      .join("")}</table>`,
    total: `${total.toFixed(2)} CHF`,
    to_email: client.email ?? "",
  };

  return send(
    "service_biq6i2q",
    "template_ic3tolm",
    template,
    "user_AhJhgm8z5Ip5IdvtedcPo"
  );
};
