import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { ProductModel } from "../models/Product";
import format from "date-fns/format";

const chunkArray = (
  myArray: ProductModel[],
  chunk_size: number
): ProductModel[][] => {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
};

const s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  const view = new Uint8Array(buf); //create uint8array as viewer
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};

export const generateXLSXForLabels = (data: ProductModel[]) => {
  const date = new Date();
  let wb = XLSX.utils.book_new();

  const title = `Etiketten Daten ${format(date, "yyyyMMdd")}`;

  wb.Props = {
    Title: title,
    Subject: "Schuhstall",
    Author: "Calvin Falter",
    CreatedDate: date,
  };

  let chunkedData = chunkArray(data, 51);

  chunkedData.forEach((chunk, index) => {
    const sheetName = `Sheet ${index + 1}`;
    wb.SheetNames.push(sheetName);
    let ws_data = [
      ["Name", "Grösse", "Preis", "ID", "Marke"],
      ...chunk.map((p) => [
        p.title,
        p.size,
        `${p.externalPrice?.toFixed(2)} CHF`,
        p.id,
        p.brand,
      ]),
    ]; //a row with 2 columns
    wb.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(ws_data);
  });

  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  saveAs(
    new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
    `${title}.xlsx`
  );
};
