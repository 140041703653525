import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ClientModel } from "../models/Client";
import { ProductModel, ProductStates } from "../models/Product";
import styled from "styled-components";
import {
  Button,
  Container,
  IconButton,
  Snackbar,
  Typography,
} from "@material-ui/core";
import useAppState from "../redux/useAppState";
import { fetchPayOutProducts } from "../redux/products/actions";
import { fetchClients } from "../redux/clients/actions";
import { Email, Visibility } from "@material-ui/icons";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { getEmailContent, sendEmailWithContent } from "../helper/email-sender";
import Modal from "@material-ui/core/Modal";
import { CLIENTS } from "../constants/routes";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import UnstyledLink from "./UnstyledLink";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-row-gap: 10px;
  margin: 10px 0 20px 0;
`;

type ClientWithProducts = {
  client: ClientModel;
  products: ProductModel[];
};

type MailOverviewItemProps = {
  clientWithProducts: ClientWithProducts;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

const MailOverviewItem: React.FC<MailOverviewItemProps> = ({
  clientWithProducts,
  setSuccess,
  setError,
}) => {
  const sum = useMemo(() => {
    let intermediate = 0;
    for (const product of clientWithProducts.products) {
      intermediate += product.internalPrice ?? 0;
    }
    return intermediate;
  }, [clientWithProducts]);

  const [previewActive, setPreviewActive] = useState(false);

  const handleSendEmail = useCallback(async () => {
    try {
      const res = await sendEmailWithContent({
        client: clientWithProducts.client,
        products: clientWithProducts.products,
        total: sum,
      });

      if (res.status < 200 || res.status >= 300) throw new Error(res.text);

      setSuccess(true);
    } catch (e) {
      console.log(e);
      setError(true);
    }
  }, [clientWithProducts, setSuccess, setError, sum]);

  return (
    <div style={{ marginBottom: 30 }}>
      <Typography variant="h6">
        {clientWithProducts.client.lastname}{" "}
        {clientWithProducts.client.firstname}
        <UnstyledLink
          to={CLIENTS + "/" + clientWithProducts.client.id}
          style={{ float: "right" }}
        >
          <IconButton edge="end" aria-label="delete">
            <KeyboardArrowRightIcon />
          </IconButton>
        </UnstyledLink>
      </Typography>
      <Typography variant="caption">
        {clientWithProducts.client.email !== ""
          ? clientWithProducts.client.email
          : "Keine Email hinterlegt"}
      </Typography>
      <ProductsGrid>
        {clientWithProducts.products.map((p) => [
          <div>
            <Typography variant="body1">{p.title}</Typography>
            <Typography variant="caption">{p.id}</Typography>
          </div>,
          <Typography variant="body1">{p.internalPrice} CHF</Typography>,
        ])}
        <div />
        <Typography variant="button">{sum} CHF</Typography>
      </ProductsGrid>
      <div>
        <Button
          variant="contained"
          startIcon={<Visibility />}
          onClick={() => setPreviewActive(true)}
          style={{ marginRight: 20, marginBottom: 16 }}
        >
          Email Vorschau
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Email />}
          onClick={handleSendEmail}
          style={{ marginRight: 20, marginBottom: 16 }}
          disabled={clientWithProducts.client.email === ""}
        >
          Email versenden
        </Button>
      </div>
      {previewActive && (
        <Modal
          open={previewActive}
          onClose={() => setPreviewActive(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{ backgroundColor: "white", padding: 20, margin: 20 }}
            dangerouslySetInnerHTML={{
              __html: getEmailContent({
                client: clientWithProducts.client,
                products: clientWithProducts.products,
                total: sum,
              }),
            }}
          />
        </Modal>
      )}
    </div>
  );
};

type Props = {
  setAppBarTitle: (title: string) => void;
};

export const MailOverview: React.FC<Props> = ({ setAppBarTitle }) => {
  useEffect(() => {
    setAppBarTitle("Website");
  }, [setAppBarTitle]);

  const dispatch = useDispatch();

  const {
    clients,
    initialFetch,
    loading: loadingClients,
  } = useAppState((st) => st.clients);

  const {
    products,
    initialFetchPayout,
    loading: loadingProducts,
  } = useAppState((state) => state.products);

  const payOutProducts = useMemo(
    () => products.filter((p) => p.state === ProductStates.SOLD),
    [products]
  );

  useEffect(() => {
    if (!loadingClients && !initialFetch) dispatch(fetchClients());
  }, [loadingClients, initialFetch, dispatch]);

  useEffect(() => {
    if (!loadingProducts && !initialFetchPayout)
      dispatch(fetchPayOutProducts());
  }, [loadingProducts, initialFetchPayout, dispatch]);

  const data = useMemo(() => {
    let compressed: ClientWithProducts[] = [];

    for (const product of payOutProducts) {
      const idx = compressed.findIndex((cp) => cp.client.id === product.owner);
      if (idx < 0) {
        const client = clients.find((c) => c.id === product.owner);
        if (!client) {
          console.log("Client not found", product.owner);
          continue;
        }
        compressed.push({ client, products: [product] });
      } else {
        compressed[idx].products.push(product);
      }
    }

    return compressed.sort((a, b) => {
      const nameA = a.client.lastname?.toUpperCase() ?? "";
      const nameB = b.client.lastname?.toUpperCase() ?? "";
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }, [clients, payOutProducts]);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <>
      <Wrapper>
        <Typography variant="h5" style={{ marginBottom: 30 }}>
          Auszahlungsmails
        </Typography>
        {data.map((d) => (
          <MailOverviewItem
            clientWithProducts={d}
            setError={setError}
            setSuccess={setSuccess}
          />
        ))}
      </Wrapper>{" "}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          Email erfolgreich versendet
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert onClose={() => setError(false)} severity="error">
          Email konnte nicht versendet werden. Bitte manuell versuchen.
        </Alert>
      </Snackbar>
    </>
  );
};
