import React, {useEffect, useMemo, useState} from "react";
import {
  Container,
  IconButton,
  List as MaterialList,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { CLIENTS } from "../constants/routes";
import UnstyledLink from "./UnstyledLink";
import { useDispatch } from "react-redux";
import useAppState from "../redux/useAppState";
import { ClientModel } from "../models/Client";
import { fetchClients } from "../redux/clients/actions";
import { AutoSizer, List, WindowScroller } from "react-virtualized";
import { ListRowProps } from "react-virtualized/dist/es/List";

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};

const Clients = (props: Props) => {
  const dispatch = useDispatch();
  const { clients, loading, initialFetch } = useAppState(
    (state) => state.clients
  );

  const [filteredClients, setFilteredClients] = useState<ClientModel[]>([]);
  const [searchString, setSearchString] = useState("");

  const rowRenderer = useMemo(() => ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }: ListRowProps) => {
    return (
      <div key={key} style={style}>
        <ListItem>
          <ListItemText
            primary={
              filteredClients[index].lastname +
              " " +
              filteredClients[index].firstname
            }
            secondary={
              filteredClients[index].address +
              ", " +
              filteredClients[index].city
            }
          />
          <ListItemSecondaryAction>
            <UnstyledLink to={CLIENTS + "/" + filteredClients[index].id}>
              <IconButton edge="end" aria-label="delete">
                <KeyboardArrowRightIcon />
              </IconButton>
            </UnstyledLink>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  }, [filteredClients]);

  useEffect(() => {
    if (!loading && !initialFetch) {
      dispatch(fetchClients());
    }
  }, [loading, dispatch, initialFetch]);

  useEffect(() => {
    if (searchString === "") setFilteredClients(clients);
    else {
      let currentClients = clients.filter((value) => {
        return (
          value?.firstname
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase()) ||
          value?.lastname
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        );
      });

      setFilteredClients(currentClients);
    }
  }, [setFilteredClients, searchString, clients]);

  useEffect(() => {
    props.setAppBarTitle("Lieferanten");
  }, [props]);

  return (
    <WindowScroller>
      {({ height, isScrolling, scrollTop }) => (
        <Wrapper>
          <Typography variant="h6">Liste aller Lieferanten</Typography>
          <TextField
            id="standard-search"
            label="Suchen"
            type="search"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
          <MaterialList>
            <AutoSizer>
              {({ width }) => (
                <List
                  autoHeight
                  height={height}
                  isScrolling={isScrolling}
                  rowCount={filteredClients.length}
                  rowHeight={70}
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop}
                  width={width}
                />
              )}
            </AutoSizer>
          </MaterialList>
        </Wrapper>
      )}
    </WindowScroller>
  );
};

export default Clients;
