import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  List as MaterialList,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import ProductCard from "../views/ProductCard";
import useAppState from "../redux/useAppState";
import { useDispatch } from "react-redux";
import { fetchProducts } from "../redux/products/actions";
import { ProductModel, ProductStates } from "../models/Product";
import { ListRowProps } from "react-virtualized/dist/es/List";
import { List, WindowScroller } from "react-virtualized";
import { AutoSizer } from "react-virtualized";

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};

const Products = (props: Props) => {
  const dispatch = useDispatch();

  const { products, loading, initialFetch } = useAppState(
    (state) => state.products
  );
  const storeProducts = useMemo(
    () => products.filter((p) => p.state === ProductStates.STORE),
    [products]
  );

  const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([]);
  const [searchString, setSearchString] = useState("");

  const rowRenderer = useMemo(
    () =>
      ({
        key, // Unique key within array of rows
        index, // Index of row within collection
        style, // Style object to be applied to row (to position it)
      }: ListRowProps) => {
        return (
          <div key={key} style={style}>
            <ProductCard product={filteredProducts[index]} />
          </div>
        );
      },
    [filteredProducts]
  );

  useEffect(() => {
    if (!loading && !initialFetch) dispatch(fetchProducts());
  }, [loading, initialFetch, dispatch]);

  useEffect(() => {
    if (searchString === "") setFilteredProducts(storeProducts);
    else {
      let currentProducts = storeProducts.filter((value) => {
        return (
          value?.id
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase()) ||
          value?.brand
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase()) ||
          value?.title
            ?.toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        );
      });
      setFilteredProducts(currentProducts);
    }
  }, [setFilteredProducts, searchString, storeProducts]);

  useEffect(() => {
    props.setAppBarTitle("Produkte");
  }, [props]);

  return (
    <WindowScroller>
      {({ height, isScrolling, scrollTop }) => (
        <Wrapper>
          <Typography variant="h6">Liste aller Produkte</Typography>
          <TextField
            id="standard-search"
            label="Suchen"
            type="search"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
          <MaterialList>
            <AutoSizer>
              {({ width }) => (
                <List
                  autoHeight
                  height={height}
                  isScrolling={isScrolling}
                  rowCount={filteredProducts.length}
                  rowHeight={width > 700 ? 203 : 423}
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop}
                  width={width}
                />
              )}
            </AutoSizer>
          </MaterialList>
        </Wrapper>
      )}
    </WindowScroller>
  );
};

export default Products;
