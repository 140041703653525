import React, { useCallback, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupIcon from "@material-ui/icons/Group";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import StoreIcon from "@material-ui/icons/Store";
import ShopIcon from "@material-ui/icons/Shop";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {Email, Language, Menu} from "@material-ui/icons";
import UnstyledLink from "./UnstyledLink";
import CategoryIcon from "@material-ui/icons/Category";
import StyleIcon from "@material-ui/icons/Style";
import BackupIcon from "@material-ui/icons/Backup";

import {
  BACKUP,
  CATEGORYBRAND,
  CLIENTS,
  DASHBOARD,
  EMAIL,
  LABELS,
  NEWCLIENT,
  NEWPRODUCT,
  PRODUCTS,
  WEBSITE,
} from "../constants/routes";
import Firebase from "./Firebase/Firebase";

interface Props extends RouteComponentProps {
  title: string;
}

const Navigation = (props: Props) => {
  let [openDrawer, setOpenDrawer] = useState(false);

  let firebase = Firebase.getInstance();

  const onSignOut = useCallback(() => {
    firebase
      .doSignOut()
      .then(() => props.history.push("/login"))
      .catch((reason) => console.log(reason.message));
  }, [firebase, props.history]);

  const hideDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, [setOpenDrawer]);

  return (
    <div>
      <React.Fragment key={"left"}>
        <Drawer
          anchor={"left"}
          open={openDrawer}
          onClose={hideDrawer}
        >
          <List component="nav" aria-label="main mailbox folders">
            <UnstyledLink to={DASHBOARD} onClick={hideDrawer}>
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Übersicht" />
              </ListItem>
            </UnstyledLink>
            <UnstyledLink to={NEWCLIENT} onClick={hideDrawer}>
              <ListItem button>
                <ListItemIcon>
                  <PersonAddIcon />
                </ListItemIcon>
                <ListItemText primary="Neuer Lieferant" />
              </ListItem>
            </UnstyledLink>
            <UnstyledLink to={CLIENTS} onClick={hideDrawer}>
              <ListItem button>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Lieferanten" />
              </ListItem>
            </UnstyledLink>
            <UnstyledLink to={NEWPRODUCT} onClick={hideDrawer}>
              <ListItem button>
                <ListItemIcon>
                  <ShopIcon />
                </ListItemIcon>
                <ListItemText primary="Neues Produkt" />
              </ListItem>
            </UnstyledLink>
            <UnstyledLink to={PRODUCTS} onClick={hideDrawer}>
              <ListItem button>
                <ListItemIcon>
                  <StoreIcon />
                </ListItemIcon>
                <ListItemText primary="Produkte" />
              </ListItem>
            </UnstyledLink>
            <UnstyledLink to={LABELS} onClick={hideDrawer}>
              <ListItem button>
                <ListItemIcon>
                  <StyleIcon />
                </ListItemIcon>
                <ListItemText primary="Etiketten" />
              </ListItem>
            </UnstyledLink>
            <UnstyledLink
              to={CATEGORYBRAND}
              onClick={hideDrawer}
            >
              <ListItem button>
                <ListItemIcon>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText primary="Kategorien und Marken" />
              </ListItem>
            </UnstyledLink>
            <Divider />
            <UnstyledLink to={WEBSITE} onClick={hideDrawer}>
              <ListItem button>
                <ListItemIcon>
                  <Language />
                </ListItemIcon>
                <ListItemText primary="Website" />
              </ListItem>
            </UnstyledLink>
            <UnstyledLink to={EMAIL} onClick={hideDrawer}>
              <ListItem button>
                <ListItemIcon>
                  <Email />
                </ListItemIcon>
                <ListItemText primary="Email" />
              </ListItem>
            </UnstyledLink>
            <Divider />
            <UnstyledLink to={BACKUP} onClick={hideDrawer}>
              <ListItem button>
                <ListItemIcon>
                  <BackupIcon />
                </ListItemIcon>
                <ListItemText primary="Backup" />
              </ListItem>
            </UnstyledLink>
            <Divider />
            <ListItem button onClick={() => onSignOut()}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
      </React.Fragment>
      <AppBar position="static" style={{ height: "auto" }}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <Menu onClick={() => setOpenDrawer(true)} />
          </IconButton>
          <Typography variant="h6">
            <span>{props.title}</span>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(Navigation);
