export const LANDING = '/';
export const LOGGEDIN = '/app';
export const LOGIN = '/login';
export const DASHBOARD = '/app/dashboard';
export const NEWCLIENT = '/app/newclient';
export const CLIENTS = '/app/clients';
export const NEWPRODUCT = '/app/newproduct';
export const PRODUCTS = '/app/products';
export const LABELS = '/app/labels';
export const CATEGORYBRAND = '/app/category-brand';
export const BACKUP = '/app/backup'
export const WEBSITE = '/app/website'
export const EMAIL = '/app/mail'