import styled from "styled-components";
import { Button, Container, Snackbar, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Build } from "@material-ui/icons";
import axios from "axios";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const Spacer = styled.div`
  height: 20px;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};
const Website: React.FC<Props> = ({ setAppBarTitle }) => {
  useEffect(() => {
    setAppBarTitle("Website");
  }, [setAppBarTitle]);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleBuildWebsite = useCallback(async () => {
    try {
      const result = await axios.post(
        `https://webhook.gatsbyjs.com/hooks/data_source/publish/127353f0-525c-4020-83cb-1def49d01406`
      );
      console.log("triggered the build", result);
      setIsSuccess(true);
    } catch (e) {
      console.log(e);
      setIsSuccess(false);
    }
    setShowSnackbar(true);
  }, []);

  return (
    <Wrapper>
      <Typography variant="h5">Website</Typography>
      <Spacer />
      {/*TODO switch to non disabled*/}
      <Button
        variant="contained"
        startIcon={<Build />}
        onClick={handleBuildWebsite}
      >
        Website neu generieren
      </Button>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={12000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={isSuccess ? "success" : "error"}
        >
          {isSuccess
            ? "Website wird neu generiert, das dauert einige Minuten"
            : "Das Generieren konnte nicht gestartet werden. Bitte sag es Calvin :)"}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default Website;
