import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Snackbar,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { PRODUCTS } from "../constants/routes";
import UnstyledLink from "../components/UnstyledLink";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {ProductLocation, ProductModel, ProductStates} from "../models/Product";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Firebase from "../components/Firebase/Firebase";
import { useDispatch } from "react-redux";
import { changeStateOfProduct } from "../redux/products/actions";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MAX_PREVIEW_DESC_CHARS = 50;

const MyCard = styled(Card)`
  margin: 10px;
  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row;
  }
`;

const ProductImage = styled(CardMedia)`
  @media (max-width: 700px) {
    height: 200px;
  }
  @media (min-width: 700px) {
    width: 200px;
  }
`;

const PictureOverlay = styled.div`
  height: 100%;
  width: 100%;
  background-image: linear-gradient(120deg, #f1f1f1, #f3f3f3);
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PriceRow = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, minmax(100px, auto));
  margin-bottom: 20px;
`;

export const switchCurrentState = (state: ProductStates) => {
  switch (state) {
    case ProductStates.REGISTERED:
      return "erfasst";
    case ProductStates.STORE:
      return "im laden";
    case ProductStates.SOLD:
      return "verkauft";
    case ProductStates.PAID_OUT:
      return "ausbezahlt";
    case ProductStates.CHARITY:
      return "gespendet";
    case ProductStates.RETURNED:
      return "zurückgegeben";
    default:
      return "";
  }
};

export const switchCurrentLocation = (state: ProductLocation) => {
  switch (state) {
    case ProductLocation.STORE:
      return "Laden"
    case ProductLocation.STORAGE1:
      return "Keller"
    default:
      return "Laden";
  }
};

type Props = {
  product: ProductModel;
};

const ProductCard = ({ product }: Props) => {
  let firebase = Firebase.getInstance();
  const dispatch = useDispatch();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    id,
    title,
    description,
    externalPrice,
    internalPrice,
    state,
    size,
    brand,
    urls,
  } = product;

  const handleProductSelling = useCallback(() => {
    if (!id) return;
    firebase
      ?.doSellSpecificProduct(id)
      .then(() => {
        setShowSuccess(true);
        dispatch(changeStateOfProduct(id, ProductStates.SOLD));
      })
      .catch(() => setShowError(true));
  }, [firebase, id, dispatch]);

  const handleProductPayOut = useCallback(() => {
    if (!id) return;
    firebase
      ?.doPayOutSpecificProduct(id)
      .then(() => {
        setShowSuccess(true);
        dispatch(changeStateOfProduct(id, ProductStates.PAID_OUT));
      })
      .catch(() => setShowError(true));
  }, [firebase, id, dispatch]);

  const handleProductLabel = useCallback(() => {
    if (!id) return;
    firebase
      ?.doLabelSpecificProduct(id)
      .then(() => {
        setShowSuccess(true);
        dispatch(changeStateOfProduct(id, ProductStates.STORE));
      })
      .catch(() => setShowError(true));
  }, [firebase, id, dispatch]);

  const changeState = useCallback(() => {
    switch (state) {
      case ProductStates.REGISTERED:
        handleProductLabel();
        break;
      case ProductStates.STORE:
        handleProductSelling();
        break;
      case ProductStates.SOLD:
        handleProductPayOut();
        break;
    }
  }, [state, handleProductLabel, handleProductSelling, handleProductPayOut]);

  const nextPastActionState = useMemo(() => {
    switch (state) {
      case ProductStates.REGISTERED:
        return "etikettiert";
      case ProductStates.STORE:
        return "verkauft";
      case ProductStates.SOLD:
        return "ausbezahlt";
      default:
        return "";
    }
  }, [state]);

  const labelActionState = useMemo(() => {
    switch (state) {
      case ProductStates.REGISTERED:
        return "etikettieren";
      case ProductStates.STORE:
        return "verkaufen";
      case ProductStates.SOLD:
        return "ausbezahlen";
      default:
        return "";
    }
  }, [state]);

  if (!id || !state || !externalPrice || !title)
    return null;

  return (
    <React.Fragment>
      <MyCard>
        <ProductImage
          image={urls ? urls.length > 0 ? urls[0] : "" : ""}
          title="Produkt Bild"
        >
          {!(
            state === ProductStates.STORE || state === ProductStates.REGISTERED
          ) && (
            <PictureOverlay>
              <Typography variant="h6">
                {switchCurrentState(state).toUpperCase()}
              </Typography>
            </PictureOverlay>
          )}
        </ProductImage>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <UnstyledLink to={PRODUCTS + "/" + id}>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {title}
                </Typography>
                <PriceRow>
                  <Chip
                    color="secondary"
                    icon={<AttachMoneyIcon />}
                    size="small"
                    label={`${externalPrice.toFixed(2)} CHF`}
                  />
                  <Chip
                    icon={<AttachMoneyIcon />}
                    size="small"
                    label={`${internalPrice?.toFixed(2)} CHF`}
                  />
                </PriceRow>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{ maxLines: 1 }}
                >
                  {size !== "" ? `Grösse: ${size}, ` : ""}
                  {brand !== "" ? `Marke: ${brand}, ` : ""}
                  {description
                    ? description?.length > MAX_PREVIEW_DESC_CHARS
                      ? description
                          .slice(0, MAX_PREVIEW_DESC_CHARS)
                          .concat("...")
                      : description
                    : ""}
                </Typography>
              </CardContent>
            </CardActionArea>
          </UnstyledLink>
          <CardActions>
            <UnstyledLink to={PRODUCTS + "/" + id}>
              <Button size="small" color="primary">
                Mehr
              </Button>
            </UnstyledLink>
            {state !== ProductStates.PAID_OUT && (
              <Button
                size="small"
                color="primary"
                onClick={() => setShowConfirmation(true)}
              >
                {labelActionState}
              </Button>
            )}
          </CardActions>
        </div>
      </MyCard>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Produkt erfolgreich {nextPastActionState}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          Produkt konnte nicht {nextPastActionState} werden
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        open={showConfirmation}
        title={"Produkt verkaufen"}
        confirmText={labelActionState}
        onClose={(confirmation) => {
          if (confirmation) changeState();
          setShowConfirmation(false);
        }}
      >
        Möchtest du dieses Produkt {labelActionState}?
      </ConfirmationDialog>
    </React.Fragment>
  );
};

export default ProductCard;
